exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-already-verified-tsx": () => import("./../../../src/pages/already-verified.tsx" /* webpackChunkName: "component---src-pages-already-verified-tsx" */),
  "component---src-pages-claim-tsx": () => import("./../../../src/pages/claim.tsx" /* webpackChunkName: "component---src-pages-claim-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-verified-tsx": () => import("./../../../src/pages/verified.tsx" /* webpackChunkName: "component---src-pages-verified-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */)
}

